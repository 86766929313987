import React from "react"
import { graphql } from "gatsby"
import PostsDisplay from "../components/posts-display"
import Layout from "../components/layout"
import "../styles/section.css";
import "katex/dist/katex.min.css"
import PropTypes from "prop-types"
import SEO from "../components/seo"
// Components
import { Helmet } from "react-helmet"
import Nav from "../components/nav"


const SectionPage = ({
  pageContext: {
    title,
    description
  },
  location: {
    pathname
  },
  data: {
    image,
    allMdx
  },
}) => {
  const posts = allMdx.edges
    .filter(({ node }) => {
      return node.frontmatter.section
    })
    .filter(({ node }) => {
      return node.frontmatter.section === title
    })

  image = image
    ? image.childImageSharp.fixed
    : null
  return (
    <div>
      <SEO
        title={title}
        image={image}
        pathname={pathname}
        description={description}
      />
      <Helmet title={title} />
      <Layout>
        <Nav
          image
        />
        <div className={"section-header"}>
        <h2>{title}</h2>
        <p>{description}</p>
</div>
        
        <PostsDisplay posts={posts} showImage={true} />
      </Layout>
    </div>

  )
}

SectionPage.propTypes = {
  pageContext: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }),
  site: PropTypes.shape({
    siteMetadata: PropTypes.shape({
      title: PropTypes.string.isRequired,
    }),
  }),
}

export default SectionPage

export const pageQuery = graphql`
query Section($assetImage: String!) {
    site {
      siteMetadata {
        title
      }
    }
    image: file(absolutePath: { regex: $assetImage  }) {
      childImageSharp {
        fixed(width: 630) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    allMdx(sort: { fields: [frontmatter___date], order: ASC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            section
            image: featured {
              childImageSharp {
                fixed(width: 630) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
  }
`
